/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLogo } from '../../store/system';
import axios from 'axios';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import config from 'react-global-configuration';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import queryString from 'query-string';

import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { Isset, getGrupoPermission } from '../../utils';

import ContaBancaria from './ContaBancaria';
import Dados from './Dados';
// import POS from '../POS';
import Parametros from './Parametros';
import Taxas from './Taxas';
import PlanosDeVendas from '../PlanosDeVendas';
import PlanosTaxas from './PlanosTaxas';
import SMTP from './SMTP';
import Termos from './TermosCondicoes';
import UrlWebhook from './UrlWebhook';
import { useLocation } from 'react-router-dom';

import LayoutBox from './LayoutBox';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles(theme => ({
	configuracoes: {
		fontFamily: "'Exo 2', sans-serif",
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: 'black',
	},
	colorPicker: {
		display: 'flex',
		justifyContent: 'center',
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: 'black',
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 1,
	},
	popupContainer: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 9999,
	},
}));

const tabsPermissions = {
	layout: ['admin', 'gerencial', 'moderador'],
	estabelecimento: ['admin', 'gerencial', 'moderador'],
	contaBancaria: ['admin', 'gerencial', 'moderador'],
	// conta: ['all', 'moderador'],
	parametros: ['admin', 'gerencial', 'moderador'],
	pos: ['admin', 'gerencial', 'moderador'],
	taxas: ['admin', 'gerencial', 'basico', 'moderador'],
	planoVenda: ['admin', 'gerencial', 'moderador'],
	planoTaxa: ['admin', 'gerencial', 'moderador'],
	termos: ['admin', 'gerencial', 'moderador'],
	urlWebhook: ['admin', 'gerencial', 'moderador'],
};

export default props => {
	const dispatch = useDispatch();
	const location = useLocation();
	const color = useSelector(state => state.system.color);
	const url_webhook = useSelector(state => state.system.url_webhook);
	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const isElo7 = estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1;
	const isElo7Admin = estabelecimento.marketplace.id === 129 && usuario.grupo_id === 1;

	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);

	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const viewLayout = useSelector(store =>
		Isset(() => store.system.params['14'].valor) ? store.system.params['14'].valor : null
	);
	const viewPlanosVendas = useSelector(store =>
		Isset(() => store.system.params['17'].valor) ? store.system.params['17'].valor : null
	);
	const viewTaxas = useSelector(store =>
		Isset(() => store.system.params['36'].valor) ? store.system.params['36'].valor : null
	);
	const canInsertLogo = useSelector(state =>
		Isset(() => state.system.params['18'].valor) ? state.system.params['18'].valor : null
	);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const query = queryString.parse(location.search);

	const [permissions, setPermissions] = useState({});
	const [logoData, setLogoData] = useState([]);
	const [logoBoletoData, setLogoBoletoData] = useState([]);
	const [marketplaceLogoData, setMarketplaceLogoData] = useState([]);
	const [loaderData, setLoaderData] = useState([]);
	const [faviconData, setFaviconData] = useState([]);
	const [urlWebhook, setUrlWebhook] = useState({
		value: url_webhook || '',
		errors: false,
	});

	const [senha, setSenha] = useState({
		current: '',
		new: '',
		confirm: '',
		errors: false,
	});

	// const [slugUpdated, setSlugUpdated] = useState(null);
	const [tabSelected, setTabSelected] = useState(viewLayout ? 0 : 1);
	const [canViewEcSystemConfig, setCanViewEcSystemConfig] = useState(false);

	const classes = useStyles();

	const handleAtualizarClick = async (slug, value) => {
		const commonSlugs = ['primary_color'];
		//dentro do layoutcolorselector
		if (commonSlugs.includes(slug)) {
			axios
				.post(
					`${config.get(
						'apiUrl'
					)}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/${encodeURIComponent(
						value
					)}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(_ => {
					// setSlugUpdated(slug);
					setTimeout(_ => {
						// setSlugUpdated(null);
					}, 2000);
				});
		} else {
			//inserir dentro do layout file select
			switch (slug) {
				case 'logo':
				case 'logo_boleto':
				case 'marketplace_logo':
				case 'loader':
				case 'favicon':
					const formData = new FormData();

					if (!Array.isArray(value)) {
						value = [value];
					}

					value.forEach(a => {
						if (!a.type.match('image.*')) return;

						formData.append('logo', a, a.name);
					});

					const result = await axios.post(
						`${config.get(
							'apiUrl'
						)}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/_`,
						formData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
								'Access-Control-Allow-Origin': '*',
							},
						}
					);

					if (result.data.success) {
						// setSlugUpdated(slug);
						setTimeout(result => {
							// setSlugUpdated(null);
						}, 3000);
					}

					if (result.data.success && Isset(() => result.data.url) && slug === 'logo') {
						dispatch(setLogo(result.data.url));
					}
					break;

				case 'password':
					if (senha.new === '' || senha.current === '' || senha.confirm === '') {
						setSenha({ ...senha, errors: ['Preencha todos os campos'] });
						return false;
					}

					if (senha.new.length < 6) {
						setSenha({ ...senha, errors: ['A senha precisa ter no mínimo 6 digitos'] });
						return false;
					}

					if (senha.new !== senha.confirm) {
						setSenha({ ...senha, errors: ['As senhas não coincidem'] });
						return false;
					}

					let res = await axios.put(`${config.get('apiUrl')}usuarios/alterar_senha/`, senha, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});

					let type = 'success';
					if (res.data.success !== true) {
						type = 'error';
					}

					if (res.data.success) {
						setSenha({
							errors: false,
							current: '',
							new: '',
							confirm: '',
						});
					}

					dispatch(addMessage({ type: type, message: res.data.message }));
					break;
				case 'url_webhook':
					let regex = /((?:https|http|ftp)?:\/\/)?([^/,\s]+\.[^/,\s]+?)(?=\/|,|\s|$|\?|#)(.*)/g;
					if (regex.exec(urlWebhook.value)) {
						setUrlWebhook({ ...urlWebhook, errors: false });

						const result = await axios.post(
							`${config.get('apiUrl')}estabelecimentos/configuracoes/`,
							{
								slug: slug.toLowerCase(),
								value: urlWebhook.value,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
									'Access-Control-Allow-Origin': '*',
								},
							}
						);

						let type = 'success';
						if (result.data.success !== true) {
							type = 'error';
						}
						dispatch(addMessage({ type: type, message: result.data.message }));
					} else {
						setUrlWebhook({ ...urlWebhook, errors: ['URL inválida.'] });
					}
					break;
				default:
				// console.log(`${slug} não encontrado.`);
			}
		}
	};

	useEffect(() => {
		if (Isset(() => estabelecimentoAdmin.id)) {
			if (Isset(() => estabelecimentoAdmin.arquivo.url)) {
				setLogoData(estabelecimentoAdmin.arquivo.url);
			} else {
				setLogoData([]);
			}
		} else {
			if (Isset(() => estabelecimento.logo.url)) {
				//		console.log(`Valor setado em setLogoData`, estabelecimento.logo.url);
				setLogoData(estabelecimento.logo.url);
			} else {
				setLogoData([]);
			}

			if (Isset(() => estabelecimento.logoBoleto.url)) {
				setLogoBoletoData(estabelecimento.logoBoleto.url);
			} else {
				setLogoBoletoData([]);
			}
		}

		const getEcSystemConfig = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/system_config`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				if (Isset(() => res.data.data.logo_url)) {
					setMarketplaceLogoData(res.data.data.logo_url);
				} else {
					setMarketplaceLogoData([]);
				}
				if (Isset(() => res.data.data.loader_logo_url)) {
					setLoaderData(res.data.data.loader_logo_url);
				} else {
					setLoaderData([]);
				}

				if (Isset(() => res.data.data.favicon_url)) {
					setFaviconData(res.data.data.favicon_url);
				} else {
					setFaviconData([]);
				}
				setCanViewEcSystemConfig(true);
			}
		};

		getEcSystemConfig();

		if (Isset(() => query.t)) {
			switch (query.t) {
				case 'layout':
					setTabSelected(0);
					break;
				case 'contaBancaria':
					setTabSelected(1);
					break;
				case 'dados':
					setTabSelected(2);
					break;
				case 'conta':
					setTabSelected(3);
					break;
				case 'pos':
					setTabSelected(4);
					break;
				case 'taxas':
					setTabSelected(5);
					break;
				default:
			}
		}
	}, [estabelecimentoAdmin]);

	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);

		const auxPermissions = Object.keys(tabsPermissions).map(key => {
			const permission = tabsPermissions[key];
			return [key, permission.filter(value => usuarioPermission.includes(value)).length > 0];
		});

		let perm = {};
		auxPermissions.forEach(a => {
			perm[a[0]] = a[1];
		});

		// if (!viewLayout) {
		// 	perm.layout = false;
		// }

		// if (!viewPlanosVendas) {
		// 	perm.planoVenda = false;
		// }
		// console.log(perm);

		perm.planoTaxa = [1, 2, 10].includes(usuario.grupo_id) && operarMarketplace;
		perm.smtp = [1, 2].includes(usuario.grupo_id) && operarMarketplace;
		perm.taxas = viewTaxas ? perm.taxas : false;
		const onePay = usuario.marketplace.id === 8 && usuario.estabelecimento.id !== 782;
		if (onePay) {
			perm = {
				contaBancaria: true,
				pos: true,
				taxas: true,
				estabelecimento: true,
			};

			if (operarMarketplace) {
				perm.layout = true;
			}

			setTabSelected(1);
		}

		if (!Isset(() => query.t) && !onePay) {
			// console.log(Object.values(perm).findIndex(b => b));
			setTabSelected(Object.values(perm).findIndex(b => b));
		}

		setPermissions(perm);
	}, [usuario, operarMarketplace]);

	const handleChange = (e, value) => setTabSelected(value);
	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações</div>

			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				centered
				value={tabSelected}
				onChange={handleChange}
				indicatorColor="primary"
			>
				{((permissions.layout && (viewLayout || operarMarketplace)) || isElo7Admin) && (
					<Tab label="Layout" value={0} />
				)}
				{(permissions.estabelecimento || isElo7Admin) && <Tab label="Dados" value={1} />}
				{(permissions.contaBancaria || isElo7Admin) && <Tab label="Conta Bancária" value={2} />}
				{/* {(permissions.conta && <Tab label="WebHooks" value={3} />} */}
				{((permissions.parametros && !isElo7) || isElo7Admin) && <Tab label="Parâmetros" value={4} />}
				{(permissions.taxas || isElo7Admin) && <Tab label="Taxas" value={5} />}
				{((permissions.planoVenda && viewPlanosVendas) || isElo7Admin) && (
					<Tab label="Plano de Venda" value={6} />
				)}
				{(permissions.planoTaxa || isElo7Admin) && <Tab label="Tabela de Markup" value={7} />}
				{(permissions.smtp || isElo7Admin) && <Tab label="SMTP" value={8} />}
				{((permissions.termos && operarMarketplace) || isElo7Admin) && (
					<Tab label="Termos e condições" value={9} />
				)}
				{((permissions.urlWebhook && !isElo7) || isElo7Admin) && <Tab label="URL Webhook" value={10} />}
			</Tabs>

			<div className={classes.tabPanels}>
				{permissions.layout && (viewLayout || operarMarketplace || canViewEcSystemConfig) && tabSelected === 0 && (
					<Typography component="div" role="tabpanel">
						<Grid container spacing={2}>
							{(canInsertLogo || operarMarketplace) && (
								<>
									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Logo"
											slug="logo"
											logoData={logoData}
											setLogoData={setLogoData}
											estabelecimentoId={estabelecimentoId}
											token={token}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Logo Boleto"
											slug="logo_boleto"
											logoData={logoBoletoData}
											setLogoData={setLogoBoletoData}
											estabelecimentoId={estabelecimentoId}
											token={token}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Cor"
											slug="primary_color"
											onUpdateClick={handleAtualizarClick}
											estabelecimentoId={estabelecimentoId}
											dispatch={dispatch}
											color={color}
											token={token}
										/>
									</Grid>
								</>
							)}
						</Grid>
						{canViewEcSystemConfig && (
							<Grid container spacing={2}>
								<>
									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Marketplace Logo "
											slug="marketplace_logo"
											logoData={marketplaceLogoData}
											setLogoData={setMarketplaceLogoData}
											estabelecimentoId={estabelecimentoId}
											token={token}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Loader"
											slug="loader"
											logoData={loaderData}
											setLogoData={setLoaderData}
											estabelecimentoId={estabelecimentoId}
											token={token}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={3} lg={3}>
										<LayoutBox
											title="Favicon"
											slug="favicon"
											logoData={faviconData}
											setLogoData={setFaviconData}
											estabelecimentoId={estabelecimentoId}
											token={token}
										/>
									</Grid>
								</>
							</Grid>
						)}
					</Typography>
				)}

				{permissions.estabelecimento && tabSelected === 1 && (
					<Typography component="div" role="tabpanel">
						<Dados estabelecimentoId={estabelecimentoId} />
					</Typography>
				)}

				{permissions.contaBancaria && tabSelected === 2 && (
					<Typography component="div" role="tabpanel">
						<ContaBancaria estabelecimentoId={estabelecimentoId} />
					</Typography>
				)}

				{permissions.parametros && tabSelected === 4 && <Parametros estabelecimentoId={estabelecimentoId} />}

				{/* {permissions.pos && tabSelected === 4 && <POS estabelecimentoId={estabelecimentoId} />} */}

				{permissions.taxas && tabSelected === 5 && (
					<>
						<Taxas estabelecimentoId={estabelecimentoId} />
						<Taxas estabelecimentoId={estabelecimentoId} tipoVendaId={2} />
					</>
				)}

				{permissions.planoVenda && viewPlanosVendas && tabSelected === 6 && (
					<PlanosDeVendas estabelecimentoId={estabelecimentoId} />
				)}

				{permissions.planoTaxa && tabSelected === 7 && <PlanosTaxas estabelecimentoId={estabelecimentoId} />}

				{permissions.smtp && tabSelected === 8 && <SMTP />}
				{permissions.termos && tabSelected === 9 && <Termos usuario={usuario} />}
				{permissions.urlWebhook && tabSelected === 10 && <UrlWebhook estabelecimentoId={estabelecimentoId} />}
			</div>
		</div>
	);
};
