/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ZText from '../system/ZText';
import ZLoader from '../system/ZLoader';
import ZModalConfirm from '../system/ZModalConfirm';
import { addMessage } from '../../store/alert';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 10,
    },
    configUpdateBtn: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 15,
        fontWeight: 700,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            fontSize: 13,
        },
    },
    configResetBtn: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 15,
        fontWeight: 700,
        fontSize: 10,
        cursor: 'pointer',
        color: '#f44336',
        '&:hover': {
            fontSize: 11,
        },
    },
    passed: {
        textAlign: 'right',
        fontSize: 18,
    },
}));

export default props => {
    const classes = useStyles();
    const { marketplaceId } = props;
    const token = useSelector(store => store.auth.token);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [novaChave, setNovaChave] = useState('');

    const handleOpenModal = async () => {
        setOpenConfirmation(true);
    };

    const handleAtualizaChave = async () => {
        setLoading(true);

        try {
            await axios.post(
                `${config.get('apiUrl')}marketplaces/${marketplaceId}/atualizar-chave`, {
                novaChave: novaChave,
            },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
        } catch (error) {
            console.error('Erro ao atualizar chave:', error);
            dispatch(addMessage({ type: 'error', message: 'Não foi possivel alterar sua chave' }));
        } finally {
            dispatch(addMessage({ type: 'success', message: 'Chave salva com sucesso.' }));
            setOpenConfirmation(false);
            setLoading(false);
        }
    };

    if (loading) {
        return <ZLoader height={200} />;
    }

    return (
        <Grid item container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                    <ZText
                        label="Valor Nova chave"
                        onBlur={e => setNovaChave(e.target.value)}
                        value={novaChave}
                        placeholder="chave ZPK"
                    />

                    <div className={classes.configUpdateBtn} onClick={handleOpenModal}>
                        Atualizar
                    </div>

                    <ZModalConfirm
                        title={`Confirmação`}
                        message={`Tem certeza que deseja alterar a chave ZPK?`}
                        open={openConfirmation}
                        onClose={handleAtualizaChave}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};
