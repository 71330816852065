import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PlanosTaxas from './PlanosTaxas';
import Parametros from './Parametros';
import SMTP from './SMTP';
import ChaveEdit from './ChaveEdit';
import TermosCondicoes from './TermosCondicoes';

const useStyles = makeStyles(theme => ({
	// configuracoes: {
	// 	fontFamily: "'Exo 2', sans-serif",
	// 	width: '100%',
	// 	flexGrow: 1,
	// },
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	colorPicker: {
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
}));

export default props => {
	const classes = useStyles();
	const usuario = useSelector(state => state.auth.usuario);
	const marketplace = useSelector(state => state.auth.usuario.marketplace);
	const [tabSelected, setTabSelected] = useState(0);

	const handleChange = (e, value) => setTabSelected(value);

	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações - Marketplace</div>

			<Tabs variant="scrollable" value={tabSelected} onChange={handleChange} centered>
				<Tab label="Parâmetros" value={0} />
				<Tab label="Tabelas de Markup" value={1} />
				<Tab label="SMTP" value={2} />
				<Tab label="Termos e Condições de Uso " value={3} />
				<Tab label="Alterar Chave ZPK" value={4} />
			</Tabs>
			<div className={classes.tabPanels}>
				{tabSelected === 0 && <Parametros marketplaceId={marketplace.id} />}
				{tabSelected === 1 && <PlanosTaxas marketplaceId={marketplace.id} />}
				{tabSelected === 2 && <SMTP />}
				{tabSelected === 3 && <TermosCondicoes usuario={usuario} />}
				{tabSelected === 4 && <ChaveEdit marketplaceId={marketplace.id} />}
				{/* {tabSelected === 3 && <TermosCondicoes marketplaceId={marketplace.id}/>} */}
			</div>
		</div>
	);
};
