import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLogo } from '../../../store/system';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import config from 'react-global-configuration';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { wait } from '../../../utils';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);

const useStyles = makeStyles(theme => ({
	configuracoes: {
		fontFamily: "'Exo 2', sans-serif",
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: 'black',
	},
}));

const LayoutFileSelector = props => {
	const { estabelecimentoId, slug, setSlugUpdated, submit, setSubmit, logoData, setLogoData, token } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isFirsRender, setIsFirstRender] = useState(true);
	const handleUpdateClick = useCallback(
		async (slug, logoData) => {
			const formData = new FormData();

			if (!Array.isArray(logoData)) {
				logoData = [logoData];
			}

			logoData.forEach(a => {
				if (!a.type.match('image.*')) return;

				formData.append('logo', a, a.name);
			});

			const result = await axios.post(
				`${config.get('apiUrl')}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/_`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Access-Control-Allow-Origin': '*',
					},
				}
			);

			if (result.data.success) {
				setSubmit(false);
				setSlugUpdated(slug);
				setTimeout(() => {
					setSlugUpdated(null);
				}, 3000);
			}

			if (result.data.success && result.data.url && slug === 'logo') {
				dispatch(setLogo(result.data.url));
			}
		},
		[estabelecimentoId, token, setSubmit, setSlugUpdated, dispatch]
	);

	// Função para gerenciar atualização de arquivos

	useEffect(() => {
		if (submit) {
			handleUpdateClick(slug, logoData);
		}
		//setIsFirstRender(false);
	}, [submit, slug, logoData, handleUpdateClick]);


	// const [isFirstRender, setIsFirstRender] = useState(true);
	// useEffect(() => {
	// 	setIsFirstRender(false);
	// }, []);
	// const handleUpdateFiles = fileItems => {
	// 	if (isFirstRender) {
	// 		return; // Não faça nada na primeira renderização
	// 	}

	// 	if (typeof logoData === 'string') {
	// 		return;
	// 	}
	// 	setLogoData(fileItems.map(fileItem => fileItem.file));
	// };
	// console.log('logoData', slug, logoData);
	const handleError = error => {
		console.error('Erro ao carregar o arquivo:', error, ' usado foi: ' + logoData[0].name);
		console.log(logoData[0]);

	};
	return (
		<div className={classes.configBox}>
			<div>
				<FilePond
					onerror={handleError}
					server={{
						fetch: (a, b) => {
							fetch(a, {
								method: 'get',
								cache: 'no-cache',
								headers: {
									Origin: 'https://zspay.com.br',
								},
								mode: 'cors',
							})
								.then(res => res.blob())
								.then(b);
						},
						process: null,
					}}
					files={logoData}
					allowMultiple={false}
					onupdatefiles={async fileItems => {
						if (Array.isArray(logoData)) {
							setLogoData(fileItems.map(fileItem => fileItem.file));
						} else {
							await wait(3000);
							setLogoData(fileItems.map(fileItem => fileItem.file));
						}
					}}
					// onupdatefiles={async fileItems => {
					// 	if (Array.isArray(logoData)) {
					// 		setLogoData(fileItems.map(fileItem => fileItem.file));
					// 	} else {
					// 		return false;
					// 	}
					// }}
					labelIdle='Arraste e solte ou <span class="filepond--label-action">clique para carregar</span>'
					acceptedFileTypes={
						slug === 'logo_boleto' ? ['image/png', 'image/jpeg'] : ['image/*', 'image/svg+xml', 'image/png']
					}
					labelFileTypeNotAllowed="Insira imagens .png ou .jpeg."
					allowFileTypeValidation={true}
				/>
			</div>
		</div>
	);
};
export default LayoutFileSelector;
